import service from '@/services/modules/helpers/mtc'

export default {
  GET_CONSTANCE(_, params) {
    return new Promise((resolve, reject) => {
      service.getConstance(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
