import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/catalogo/empresa'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?tipo=${params.tipo}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findById(params) {
    const query = `/${params.id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const formData = new FormData()
    formData.append('ruc', model.ruc)
    formData.append('razonSocial', model.razonSocial)
    formData.append('direccion', model.direccion)
    formData.append('idUbigeo', model.ubigeo.idUbigeo)
    if (model.logo !== null) formData.append('logo', model.logo)
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const formData = new FormData()
    formData.append('idEmpresa', model.idEmpresa)
    formData.append('ruc', model.ruc)
    formData.append('razonSocial', model.razonSocial)
    formData.append('direccion', model.direccion)
    formData.append('idUbigeo', model.ubigeo.idUbigeo)
    if (model.logo !== null) formData.append('logo', model.logo)
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
