// import actions
import educationalInstitutionAction from './actions/educational-institution'
import educationalInstitutionDirectoryAction from './actions/educational-institution-directory'
import institutionAction from './actions/institution'
import institutionDirectoryAction from './actions/institution-directory'
import riskPlaceAction from './actions/risk-place'
import studentAction from './actions/student'
import incidenceAction from './actions/incidence'
import treatmentAction from './actions/treatment'
// import getters

// import mutations

// import states

const actions = { ...educationalInstitutionAction, ...educationalInstitutionDirectoryAction, ...institutionAction, ...institutionDirectoryAction, ...riskPlaceAction, ...studentAction, ...incidenceAction, ...treatmentAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
