// import actions
import boardAction from './actions/board'
// import getters

// import mutations

// import states

const actions = { ...boardAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
