// import actions
import typeInstitutionAction from './actions/type-institution'
import typeRiskPlace from './actions/type-risk-place'
import educationalInstitutionAction from '../ppptcd/actions/educational-institution'
import ubigeoAction from './actions/ubigeo'
// import getters

// import mutations

// import states

const actions = { ...typeInstitutionAction, ...typeRiskPlace, ...educationalInstitutionAction, ...ubigeoAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
