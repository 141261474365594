export default [
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/seguridad/permisos',
    name: 'rt-seguridad-permisos',
    component: () => import('@/views/devida/security/permission/Permission.vue'),
    meta: {
      resource: 'rt-seguridad-permisos',
      pageTitle: 'Permisos de Perfiles',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Permisos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguridad/modulos',
    name: 'rt-seguridad-modulos',
    component: () => import('@/views/devida/security/module/ModuleList.vue'),
    meta: {
      resource: 'rt-seguridad-sistemas',
      pageTitle: 'Módulos',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Módulos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguridad/perfiles',
    name: 'rt-seguridad-perfiles',
    component: () => import('@/views/devida/security/profile/PerfilList.vue'),
    meta: {
      resource: 'rt-seguridad-perfiles',
      pageTitle: 'Perfiles',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Perfiles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/seguridad/usuarios',
    name: 'rt-seguridad-usuarios',
    component: () => import('@/views/devida/security/user/UserList.vue'),
    meta: {
      resource: 'rt-seguridad-usuarios',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
]
