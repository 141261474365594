// import actions
import marcaAction from './actions/marca'
import presentacionAction from './actions/presentacion'
import tipoFlotaAction from './actions/tipo_flota'
import tipoDocumentoAction from './actions/tipo_documento'
import documentoAction from './actions/documento'
import sedeAction from './actions/sede'
import areaAction from './actions/area'
import ubigeoAction from './actions/ubigeo'
import empresaAction from './actions/empresa'
// import getters
import sedeGetter from './getters/sede'

// import mutations
import sedeMutation from './mutations/sede'

// import states
import sedeState from './states/sede'

const actions = { ...marcaAction, ...presentacionAction, ...tipoFlotaAction, ...tipoDocumentoAction, ...documentoAction, ...sedeAction, ...areaAction, ...ubigeoAction, ...empresaAction }

const getters = { ...sedeGetter }

const mutations = { ...sedeMutation }

const state = { ...sedeState }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
