// import actions
import controlAction from './actions/control-ingreso-salida'

// import getters

// import mutations

// import states

const actions = { ...controlAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
