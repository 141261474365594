/* eslint-disable no-unused-vars */
import apiCall from '@/services'

const apiService = '/api-ppptcd/api/v1/report'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = '/main_board'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
