import service from '@/services/modules/facturacion/facturacion'

export default {
  GET_PRODUCTO_SEARCH_FACTURACION(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_REPORTE_HISTORICO_VENTAS(_, params) {
    return new Promise((resolve, reject) => {
      service.reporteHistoricoVentas(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_REPORTE_TOP_VENDEDORES(_, params) {
    return new Promise((resolve, reject) => {
      service.reporteTopVendedores(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_REPORTE_VENTAS_PRODUCTOS(_, params) {
    return new Promise((resolve, reject) => {
      service.reporteVentasProductos(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_REPORTE_VENTAS_VENDEDOR(_, params) {
    return new Promise((resolve, reject) => {
      service.reporteVentasVendedor(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
