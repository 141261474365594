// import actions
import reniecAction from './actions/reniec'
import rucAction from './actions/ruc'
import mtcAction from './actions/mtc'

const actions = {
  ...reniecAction, ...rucAction, ...mtcAction,
}

export default {
  namespaced: true,
  actions,
}
