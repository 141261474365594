// import actions
import escaleAction from './actions/escale'
import reniecAction from './actions/reniec'
// import getters

// import mutations

// import states

const actions = { ...escaleAction, ...reniecAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
