import apiCall from '@/services'

const apiService = '/api-ppptcd/api/v1/services/escale'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?estado=${params.estado}&nombreIE=${params.nombreIE}&codmod=${params.codmod}&ubigeo=${params.ubigeo}&ugel=${params.ugel}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
