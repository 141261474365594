export default [
  {
    path: '/configuracion/tipo-institucion',
    name: 'rt-setting-type-institution',
    component: () => import('@/views/devida/setting/type-institution/TypeInstitutionList.vue'),
    meta: {
      resource: 'rt-setting-type-institution',
      pageTitle: 'Tipos de Instituciones',
      breadcrumb: [
        {
          text: 'Configuración',
        },
        {
          text: 'Tipos de Instituciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configuracion/tipo-lugar-riesgo',
    name: 'rt-setting-type-risk-place',
    component: () => import('@/views/devida/setting/type-risk-place/TypeRiskPlaceList.vue'),
    meta: {
      resource: 'rt-setting-type-risk-place',
      pageTitle: 'Tipos de Lugares de Riesgo',
      breadcrumb: [
        {
          text: 'Configuración',
        },
        {
          text: 'Tipos de Lugares de Riesgo',
          active: true,
        },
      ],
    },
  },
]
