import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/facturacion'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `/producto?limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reporteHistoricoVentas(params) {
    const query = `/reporte_historico_ventas?startDate=${params.startDate}&endDate=${params.endDate}&isSend=${params.isSend}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reporteTopVendedores(params) {
    const query = `/reporte_top_vendores?startDate=${params.startDate}&endDate=${params.endDate}&isSend=${params.isSend}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reporteVentasProductos(params) {
    const query = `/reporte_ventas_productos?startDate=${params.startDate}&endDate=${params.endDate}&isSend=${params.isSend}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  reporteVentasVendedor(params) {
    const query = `/reporte_ventas_vendedor?idVendedor=${params.idVendedor}&startDate=${params.startDate}&endDate=${params.endDate}&isSend=${params.isSend}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
