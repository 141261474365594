import service from '@/services/devida/modules/report/board'

export default {
  GET_MAIN_BOARD(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
