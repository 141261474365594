// import actions
import flotarAction from './actions/flota'
import tanqueEstacionarioAction from './actions/tanque_estacionario'

// import getters

// import mutations

// import states

const actions = { ...flotarAction, ...tanqueEstacionarioAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
