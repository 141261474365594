export default [
  {
    path: '/ppptcd/tablero',
    name: 'rt-board-main',
    component: () => import('@/views/devida/pppptcd/tablero/Ecommerce.vue'),
  },
  {
    path: '/ppptcd/casos',
    name: 'rt-ppptcd-cases',
    component: () => import('@/views/devida/pppptcd/cases/CasesList.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/ppptcd/lugares-de-riesgo',
    name: 'rt-ppptcd-risk-place',
    component: () => import('@/views/devida/pppptcd/risk-place/RiskPlaceList.vue'),
    meta: {
      resource: 'rt-ppptcd-risk-place',
      pageTitle: 'Lugares de Riesgo',
      breadcrumb: [
        {
          text: 'Ppptcd',
        },
        {
          text: 'Lugares de Riesgo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ppptcd/institucion-educativa',
    name: 'rt-ppptcd-educational-institution',
    component: () => import('@/views/devida/pppptcd/educational-institution/EducationalInstitutionList.vue'),
    meta: {
      resource: 'rt-ppptcd-educational-institution',
      pageTitle: 'Instituciones Educativas Focalizadas',
      breadcrumb: [
        {
          text: 'Ppptcd',
        },
        {
          text: 'Instituciones Educativas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ppptcd/institucion',
    name: 'rt-ppptcd-institution',
    component: () => import('@/views/devida/pppptcd/institution/InstitutionList.vue'),
    meta: {
      resource: 'rt-ppptcd-institution',
      pageTitle: 'Aliados Estratégicos',
      breadcrumb: [
        {
          text: 'Ppptcd',
        },
        {
          text: 'Aliados Estratégicos',
          active: true,
        },
      ],
    },
  },
]
