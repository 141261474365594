import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '48900',
  },
  statisticsItems: [
    {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: '25',
      subtitle: 'I.E. Focalizadas',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-info',
      title: '45',
      subtitle: 'Aliados',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'FlagIcon',
      color: 'light-danger',
      title: '87',
      subtitle: 'Lugares de Riesgo',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'FilePlusIcon',
      color: 'light-success',
      title: '48',
      subtitle: 'Casos',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2023',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ['2023', '2022', '2021'],
    price: '25,852',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: 'Expense',
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    {
      avatarImg: require('@/assets/images/icons/toolbox.svg'),
      title: 'Moyobamba',
      subtitle: 'Casos en 3 I.E.',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '10',
      viewsub: 'en total',
      revenue: '102',
      sales: '68',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/parachute.svg'),
      title: 'San Martín',
      subtitle: 'Casos en 5 I.E.',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '15',
      viewsub: 'en total',
      revenue: '153',
      sales: '97',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/brush.svg'),
      title: 'Tocache',
      subtitle: 'Casos en 2 I.E.',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '4',
      viewsub: 'en total',
      revenue: '80',
      sales: '62',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/star.svg'),
      title: 'Lamas',
      subtitle: 'Casos en 3 I.E.',
      avatarIcon: 'MonitorIcon',
      avatarColor: 'light-primary',
      avatarTitle: 'Technology',
      viewTitle: '7',
      viewsub: 'en total',
      revenue: '75',
      sales: '88',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/book.svg'),
      title: 'Picota',
      subtitle: 'Casos en 1 I.E.',
      avatarIcon: 'CoffeeIcon',
      avatarColor: 'light-success',
      avatarTitle: 'Grocery',
      viewTitle: '13',
      viewsub: 'en total',
      revenue: '40',
      sales: '16',
      loss: true,
    },
    {
      avatarImg: require('@/assets/images/icons/rocket.svg'),
      title: 'El Dorado',
      subtitle: 'Casos en 1 I.E.',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '9',
      viewsub: 'en total',
      revenue: '68',
      sales: '78',
      loss: false,
    },
    {
      avatarImg: require('@/assets/images/icons/speaker.svg'),
      title: 'Rioja',
      subtitle: 'Casos en 2 I.E.',
      avatarIcon: 'WatchIcon',
      avatarColor: 'light-warning',
      avatarTitle: 'Fashion',
      viewTitle: '6',
      viewsub: 'en total',
      revenue: '8',
      sales: '42',
      loss: false,
    },
  ],
  meetup: {
    mediaData: [
      { avatar: 'CalendarIcon', title: 'Jue, Dic 21, 2023', subtitle: '10:AM to 6:PM' },
      { avatar: 'MapPinIcon', title: 'Moyobamba', subtitle: 'Centro de Salud' },
    ],
    avatars: [
      { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
      /* { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' }, */
    ],
  },
  goalOverview: {
    completed: '786,617',
    inProgress: '13,561',
    series: [83],
  },
  transactionData: [
    {
      mode: 'Wallet',
      types: 'Starbucks',
      avatar: 'PocketIcon',
      avatarVariant: 'light-primary',
      payment: '-$74',
      deduction: true,
    },
    {
      mode: 'Bank Transfer',
      types: 'Add Money',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Paypal',
      types: 'Add Money',
      avatar: 'DollarSignIcon',
      avatarVariant: 'light-danger',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Mastercard',
      types: 'Ordered Food',
      avatar: 'CreditCardIcon',
      avatarVariant: 'light-warning',
      payment: '-$23',
      deduction: true,
    },
    {
      mode: 'Transfer',
      types: 'Refund',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '+$98',
      deduction: false,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/ecommerce/data').reply(() => [200, data])
