import service from '@/services/modules/comercial/puntoventa'

export default {
  VENTAS_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  VENTAS_PARAMETERS(_, params) {
    return new Promise((resolve, reject) => {
      service.parameters(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  REPORTES_PARAMETERS(_, params) {
    return new Promise((resolve, reject) => {
      service.reportesParameters(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  REPORTES_CONTABLE(_, params) {
    return new Promise((resolve, reject) => {
      service.reportesContable(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_SERIES(_, params) {
    return new Promise((resolve, reject) => {
      service.getSeries(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_NRO_COMPROBANTE(_, params) {
    return new Promise((resolve, reject) => {
      service.getNroComprobante(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CLIENTE_SEARCH_DOCUMENTO(_, params) {
    return new Promise((resolve, reject) => {
      service.getSearchDocument(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GET_PRODUCTO_SEARCH(_, params) {
    return new Promise((resolve, reject) => {
      service.getSearchProducto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  VENTA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.save(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  VENTA_CONSULTA_OSE(_, params) {
    return new Promise((resolve, reject) => {
      service.consulta_ose(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  VENTAS_FIND_BY_ID(_, params) {
    return new Promise((resolve, reject) => {
      service.findById(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CLIENTE_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CLIENTE_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  VENTA_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GUIA_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAllGuia(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  GUIA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.saveGuia(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  COMPRAS_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAllCompra(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  COMPRA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.saveCompra(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  COMPRA_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service.deleteCompra(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
